"use strict";

// code de la fleche + fond

let fleche = document.querySelector('.arrow'); //faire une variable + aller chercher la classe du bouton
fleche.addEventListener("click", Ouvre); //déclarer la variable et mettre un écouteur d'évenement + donner un nom a la fonction

function Ouvre(){ //commencer la fonction
    let menu = document.querySelector('.container'); //faire une variable + aller chercher la classe qu'on veut modifier
    menu.classList.toggle("flou-body"); //prendre la variable et aller mettre la nouvelle classe
};

/* Ouverture des menus */

let burger = document.querySelector(".burger");
burger.addEventListener("click", ouverture);

let arrow = document.querySelector(".arrow");
arrow.addEventListener("click", ouverture);

function ouverture(){
    if(document.body.hasAttribute("data-menu")){
        document.body.removeAttribute("data-menu");
    }else{
        document.body.setAttribute("data-menu", true);
    }
}

let croix = document.querySelector(".croix");
croix.addEventListener("click", ouverture);

/* date */

var dateEl = new Date();
document.querySelector("#date")
.innerHTML = dateEl.getFullYear();

/* tableau */

const tabs = [...document.querySelectorAll(".tabs")];
const contents = [...document.querySelectorAll(".tab")];

tabs.forEach((tab) => tab.addEventListener("click", handleTabs))

let index = 0;
function handleTabs(e){

  if(tabs.indexOf(e.target) === index) return;
  
  const currentEl = e.target;

  tabs[index].classList.remove("active");
  contents[index].classList.remove("active-tab");

  index = tabs.indexOf(currentEl);

  tabs[index].classList.add("active");
  contents[index].classList.add("active-tab");
}

/* animation */

AOS.init();

/* slider */

const slider = document.querySelector('.slideshow-container')
let holding = false;
let firstClickX;
let alreadyLeftScrolled;
let velocity;
let rafID;

if(slider){
    slider.addEventListener('mousedown', e => {
        holding = true;
        firstClickX = e.pageX - slider.offsetLeft;
        alreadyLeftScrolled = slider.scrollLeft;
        stopTransition()
    })

    slider.addEventListener('mousemove', e => {
        if(!holding) return;
        const x = e.pageX - slider.offsetLeft;
        const scrolled = (x - firstClickX) * 2;
        const prevScrollLeft = slider.scrollLeft
        slider.scrollLeft = alreadyLeftScrolled - scrolled;
        velocity = slider.scrollLeft - prevScrollLeft;
    })
    
    slider.addEventListener('mouseup', () => {
        holding = false;
        startTransition()
    })

    slider.addEventListener('mouseleave', () => {
        holding = false;
    })

    slider.addEventListener('touchstart', e => {
        holding = true;
        firstClickX = e.targetTouches[0].pageX - slider.offsetLeft;
      
        alreadyLeftScrolled = slider.scrollLeft;
        stopTransition()
    })

    slider.addEventListener('touchend', () => {
        holder = false;
        startTransition()
    })

    slider.addEventListener('touchmove', e => {
        if(!holding) return;
        const x = e.targetTouches[0].pageX - slider.offsetLeft;
        const scrolled = (x - firstClickX) * 2;
        const prevScrollLeft = slider.scrollLeft;
        slider.scrollLeft = alreadyLeftScrolled - scrolled;
        velocity = slider.scrollLeft - prevScrollLeft;
    })
}

function startTransition(){
    stopTransition();
    rafID = requestAnimationFrame(decreasingTransition);
}

function stopTransition(){
  cancelAnimationFrame(rafID)
}

function decreasingTransition(){
    slider.scrollLeft += velocity;
    velocity *= 0.95;
    if(Math.abs(velocity) > 0.5){
        rafID = requestAnimationFrame(decreasingTransition)
    }
}


// transition de page

window.onload = () => {
    const anchors = document.querySelector('.transi');
    const transition_el = document.querySelector('.transition');
  
    setTimeout(() => {
      transition_el.classList.remove('is-active');
    }, 500);
  
    for (let i = 0; i < anchors.length; i++) {
      const anchor = anchors[i];
  
        anchor.addEventListener('click', e => {
            e.preventDefault();
            let target = e.target.href;

            console.log(transition_el);

            transition_el.classList.add('is-active');

            console.log(transition_el);

            setInterval(() => {
            window.location.href = target;
            }, 500);
        })
    }
}

// generateur

let isGoogleFontLoaded = false;
let siteFonts = [{
    family: "Granary",
    variants: [100,200,300,400,500,600]
}];

const titleOptions = {
    currentHightFontSize: 128,
    currentLowFontSize: 128,
    maxFontSize: 600,
    minFontSize: 24,
    fontWeight: 200,
    fontFamily: "Granary",
    shrinkPercentage: 0.5,
    content: "De la typo pour les étudiants"
}
const textOptions = {
    fontSize: 20,
    fontWeight: 200,
    lineHeight: 135, //%
    fontFamily: "Granary",
    content: "En typographie, un caractère, ou type (mobile, typographique ou d'imprimerie), est une petite pièce, généralement en plomb typographique, destinée à recevoir de l'encre grasse avant d'être pressée sur un support, généralement en papier, pour y laisser son empreinte. Les très gros caractères mobiles peuvent être faits de bois. La partie supérieure de cette pièce (l'œil) est en relief, formant ainsi le glyphe d'un caractère d'un système d'écriture."
}

let generatedTitleNumber = 5; //when site opens
let container;
let fontSizeElem;
let elementChoiceElem;
let fontWeightElem;
let lineHeightElem;
let fontFamilyElem;
let shrinkPercentageElems;
let shrinkPercentageValue;

document.addEventListener('readystatechange', event => { 
    if (event.target.readyState === "complete") {
        container = document.getElementById("content");
        setOptions();
        generateTitles();
    }
});

function setOptions(){
    fontSizeElem = document.getElementById("font-size");
    if (fontSizeElem){
        fontSizeElem.value = titleOptions.currentHightFontSize;
        fontSizeElem.setAttribute("min", titleOptions.minFontSize.toString());
        fontSizeElem.setAttribute("max", titleOptions.maxFontSize.toString());
        elementChoiceElem = document.getElementById("element");
        fontWeightElem = document.getElementById("font-weight");
        lineHeightElem = document.getElementById("line-height");
        lineHeightElem.style.display = "none";
        fontFamilyElem = document.getElementById("font-family");
        shrinkPercentageElems = document.getElementsByName("shrink-percentage");
        for (var radio of shrinkPercentageElems)
        {
            if (radio.checked) {
                shrinkPercentageValue = radio.value
            }
        }
        let prev = null;
        for (let i = 0; i < shrinkPercentageElems.length; i++) {
            shrinkPercentageElems[i].addEventListener('change', function() {
                (prev) ? "": null;
                if (this !== prev) {
                    prev = this;
                }
                titleOptions.shrinkPercentage = Number(this.value);
                generateTitles();
            });
        }
        ["keypress","input"].forEach(evtName => fontSizeElem.addEventListener(evtName, function(event) {
            if (event.key === "Enter") {
                if(event.target.value <= titleOptions.maxFontSize){
                    titleOptions.currentHightFontSize = Number(event.target.value);
                }
                else{
                    titleOptions.currentHightFontSize = titleOptions.maxFontSize;
                    fontSizeElem.value = titleOptions.currentHightFontSize;
                }
    
                if(event.target.value >= titleOptions.minFontSize){
                    titleOptions.currentLowFontSize = Number(event.target.value);
                }
                else{
                    titleOptions.currentLowFontSize = titleOptions.minFontSize;
                    fontSizeElem.value = titleOptions.currentLowFontSize;
                }
    
                generateTitles();
            }
        }));
        const defaultFond = siteFonts.find(f => f.family === "Granary")
        for(const variant of defaultFond.variants){
            const opt = document.createElement('option');
            opt.value = variant;
            opt.innerHTML = variant;
            if(variant == 200)
                opt.selected = true;
            fontWeightElem.appendChild(opt);
        }
        lineHeightElem.value = textOptions.lineHeight;
    
        const googleFontLoading = setInterval(function () {
            if(isGoogleFontLoaded){
                clearInterval(googleFontLoading);
                fontFamilyElem.innerText = "";
                for (const font of siteFonts){
                    const opt = document.createElement('option');
                    opt.value = font.family;
                    opt.innerHTML = font.family;
                    fontFamilyElem.appendChild(opt);
                }
            }
        } ,200);
    }
};

function generateTitles(){
    const decreaseButton = document.getElementById("decreaseButton");
    if(decreaseButton){
        decreaseButton.style.display = "block";
        container.innerText = "";
        titleOptions.currentHightFontSize = Number(fontSizeElem.value) || 128;
        titleOptions.currentLowFontSize = Number(fontSizeElem.value) || 128;
        let i = 0;
        let tempTitleSize = titleOptions.currentLowFontSize;

        while(i < generatedTitleNumber && tempTitleSize >= titleOptions.minFontSize){
            const span = document.createElement("span");
            span.innerText = titleOptions.currentLowFontSize;
            span.classList.add("title-size")
    
            const title = buildTitle(titleOptions.currentLowFontSize);
            const text = buildText(textOptions.currentLowFontSize);
            const div = document.createElement("div");
            div.classList.add("content-generator");
            div.appendChild(span);
            div.appendChild(title);
            div.appendChild(text);
            container.appendChild(div);
            tempTitleSize = Number((titleOptions.currentLowFontSize - (titleOptions.currentLowFontSize * (titleOptions.shrinkPercentage))).toFixed(3));
            if(tempTitleSize > titleOptions.minFontSize)
                titleOptions.currentLowFontSize = tempTitleSize;
            if(tempTitleSize <= titleOptions.minFontSize){
                const decreaseButton = document.getElementById("decreaseButton");
                decreaseButton.style.display = "none";
            }
            i++;
        } 
    }
}

function increaseTitleSize(){
    const tempTitleSize = Number((titleOptions.currentHightFontSize + (titleOptions.currentHightFontSize * (titleOptions.shrinkPercentage))).toFixed(3));
    if(tempTitleSize < titleOptions.maxFontSize){
        titleOptions.currentHightFontSize = tempTitleSize;

        const span = document.createElement("span");
        span.innerText = titleOptions.currentHightFontSize;
        span.classList.add("title-size")

        const title = buildTitle(titleOptions.currentHightFontSize);
        const text = buildText(textOptions.currentHightFontSize);
        const div = document.createElement("div");
        div.classList.add("content-generator");
        div.appendChild(span);
        div.appendChild(title);
        div.appendChild(text);
        container.prepend(div);
    }

    if(tempTitleSize >= titleOptions.maxFontSize){
        const increaseButton = document.getElementById("increaseButton");
        increaseButton.style.display = "none";
    }
}

function decreaseTitleSize(){
    const tempTitleSize = Number((titleOptions.currentLowFontSize - (titleOptions.currentLowFontSize * (titleOptions.shrinkPercentage))).toFixed(3));
    if(tempTitleSize > titleOptions.minFontSize){
        titleOptions.currentLowFontSize = tempTitleSize;

        const span = document.createElement("span");
        span.innerText = titleOptions.currentLowFontSize;
        span.classList.add("title-size")

        const title = buildTitle(titleOptions.currentLowFontSize);
        const text = buildText(textOptions.currentLowFontSize);
        const div = document.createElement("div");
        div.classList.add("content-generator");
        div.appendChild(span);
        div.appendChild(title);
        div.appendChild(text);
        container.appendChild(div);
    }

    if(tempTitleSize <= titleOptions.minFontSize){
        const decreaseButton = document.getElementById("decreaseButton");
        decreaseButton.style.display = "none";
    }
}

function buildTitle(fontSize){
    const title = document.createElement("h3");
    title.classList.add("title-generated");
    title.innerText = titleOptions.content;
    title.style.fontSize = fontSize + "px";
    title.style.fontFamily = titleOptions.fontFamily;
    title.style.fontWeight = 200;
    
    return title;
}

function buildText(fontSize){
    const text = document.createElement("p");
    text.classList.add("text-generated");
    text.innerText = textOptions.content;
    text.style.fontSize = fontSize + "px";
    text.style.fontFamily = textOptions.fontFamily;
    
    return text;
}

(async function fetchGoogleFont(){
    const respond = await fetch("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyCq8hAyRSStAn0m9ybxTvv-C3hfKurX0yA");
    const fonts = await respond.json();
    for(const item of fonts.items){                
        const parsedFontName = item.family.replace(/ /g, "+");
        const variants = [];
        let link = `https://fonts.googleapis.com/css2?family=${parsedFontName}:wght@`;
        for(let variant of item.variants){
            if(!variant.includes("italic")){
                if(variant.includes("regular"))
                    variant = 400;
                link += `${variant};`
                variants.push(variant);
            }
        }
        const check = link.split("wght")[1];
        if(!check.includes(';')){
            link = link.replace(/:wght/g, "");
        }
        let parsedLink = link.slice(0, -1);
        parsedLink += "&display=swap";
        const fontLink = {
            family: item.family,
            link: parsedLink,
            variants: variants
        }
        siteFonts.push(fontLink);
    }
    isGoogleFontLoaded = true;
}());
function handleFontSizeChange(e){
    titleOptions.currentHightFontSize = Number(e.value);
    if(titleOptions.currentLowFontSize > titleOptions.currentHightFontSize)
        titleOptions.currentLowFontSize = titleOptions.currentHightFontSize;
}

function handleElementTypeChange(e){
    let radioButtonWrapper = document.getElementById("shrink-radio-buttons-wrapper");
    let font;
    fontWeightElem.innerHTML = "";
    if(e.selectedIndex === 0){ //titre
        font = siteFonts.find(f => f.family === titleOptions.fontFamily);
        for(const variant of font.variants){
            const opt = document.createElement('option');
            opt.value = variant;
            opt.innerHTML = variant;
            if(variant == titleOptions.fontWeight)
                opt.selected = true;
            fontWeightElem.appendChild(opt);
        }
        lineHeightElem.style.display = "none";
        fontFamilyElem.value = titleOptions.fontFamily;
        radioButtonWrapper.style.display = "inline-block";
        fontSizeElem.style.display = "inline-block";
    }
    else if(e.selectedIndex === 1){ //texte
        font = siteFonts.find(f => f.family === textOptions.fontFamily);
        for(const variant of font.variants){
            const opt = document.createElement('option');
            opt.value = variant;
            opt.innerHTML = variant;
            if(variant == textOptions.fontWeight)
                opt.selected = true;
            fontWeightElem.appendChild(opt);
        }
        lineHeightElem.style.display = "inline-block";
        fontFamilyElem.value = textOptions.fontFamily;
        radioButtonWrapper.style.display = "none";
        fontSizeElem.style.display = "none";
    }
}

function handlefontWeightChange(e){
    if(elementChoiceElem.selectedIndex === 0){
        const titles = document.getElementsByClassName("title-generated");
        for(const title of titles){
            title.style.fontWeight = e.value;
        }
        titleOptions.fontWeight = e.value;
    }else if(elementChoiceElem.selectedIndex === 1){
        const texts = document.getElementsByClassName("text-generated");
        for(const text of texts){
            text.style.fontWeight = e.value;
        }
        textOptions.fontWeight = e.value;
    }
}

function handleLineHeightChange(e){
    const texts = document.getElementsByClassName("text-generated");
    for(const text of texts){
        text.style.lineHeight = e.value + "%"
    }
    textOptions.lineHeight = e.value;
}
function handleFontFamilyChange(e){
    const chosenFont = siteFonts.find(f => f.family === e.value);
    const existingLinkTag = document.getElementById(`chosen-google-font-${elementChoiceElem.selectedIndex}`);
    if(!!existingLinkTag)
        existingLinkTag.remove();
    fontWeightElem.innerHTML = "";
    if(chosenFont.family === "Granary"){
        for(const variant of chosenFont.variants){
            const opt = document.createElement('option');
            opt.value = variant;
            opt.innerHTML = variant;
            if(variant == 200)
                opt.selected = true;
            fontWeightElem.appendChild(opt);
        }
    }else{
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = chosenFont.link;
        link.id = `chosen-google-font-${elementChoiceElem.selectedIndex}`;
        const headTag = document.getElementsByTagName("head")[0];
        headTag.appendChild(link);
        if(chosenFont.variants.length < 1){
            const opt = document.createElement('option');
            opt.value = 200;
            opt.innerHTML = 200;
            fontWeightElem.appendChild(opt);
        }else{
            for(const variant of chosenFont.variants){
                const opt = document.createElement('option');
                opt.value = variant;
                opt.innerHTML = variant;
                if(variant == 200)
                    opt.selected = true;
                fontWeightElem.appendChild(opt);
            }
        }
    }
    if(elementChoiceElem.selectedIndex === 0){
        const titles = document.getElementsByClassName("title-generated");
        for(const title of titles){
            title.style.fontFamily = chosenFont.family;
        }
        titleOptions.fontFamily = chosenFont.family;
        titleOptions.fontWeight = 200;
    }else if(elementChoiceElem.selectedIndex === 1){
        const texts = document.getElementsByClassName("text-generated");
        for(const text of texts){
            text.style.fontFamily = chosenFont.family;
        }
        textOptions.fontFamily = chosenFont.family;
        textOptions.fontWeight = 200;
    }
}